import { createApp } from "vue";
import App from "./App.vue";
import router from "@/core/router";
import store from "./stores";
import VueAxios from "vue-axios";
import axios from "./core/axios";
import VueViewer from "v-viewer";
// plugins
import plugins from "./plugins";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "mdb-vue-ui-kit/css/mdb.min.css";
import "vue-multiselect/dist/vue-multiselect.css";
import "viewerjs/dist/viewer.css";
import "@/assets/css/index.css";

const app = createApp(App);
app.use(VueAxios, axios);
app.use(router);
app.use(VueViewer);
app.use(store);
app.use(plugins);

app.mount("#app");
