import axios from 'axios';
import _ from 'lodash';
import store from "@/stores/index"

;(() => {
    const UUID = localStorage.getItem('UUID')
    if (!UUID) {
        localStorage.setItem('UUID', Math.random())
    }
})()

const globalApi = [
    "/api/web/article-category",
]

function isGlobalApi(url) {
    return globalApi.includes(url.replace(process.env.VUE_APP_HOST_API, ""))
}



const instance = axios.create({
    baseURL: process.env.VUE_APP_HOST_API,
    timeout: 30000,
    headers: {
        'X-REQUEST-ID': `WEB-${localStorage.getItem('UUID')}`,
    },
});

instance.interceptors.request.use(config => {
    // console.log("[Request api] " + config.url);
    let ward = store.state.common.ward
    if (ward.id != 0 && !isGlobalApi(config.url)) {
        config.params = {
            ...config.params,
            ward: ward.id
        }
        return config
    }
    return config;
}, error => {
    console.log(error);
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    let status = _.get(error, 'response.status', null);
    if (status && status >= 400) {
        console.log('error code: ' + error.response.status, error.response.data);
        return error.response
    }

    return error;
});

export default instance;
