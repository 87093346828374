import api from '@/api';
export default {
    actions: {
        async ListBanners() {
            const response = await this.$axios({
                method: 'GET',
                url: api.ListBanners
            })
            return response.data;
        },
        async ListMenu() {
            const response = await this.$axios({
                method: 'GET',
                url: api.ListMenu
            })
            return response.data
        },
        async GetProvinceWard(_, id) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GetProvinceWard', { id: id })
            })
            return response.data
        },

    }
}
