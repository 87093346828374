import api from "@/api"

export default {
    actions: {
        async GettCommunityLibraryDocument(_, documentId) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GettCommunityLibraryDocument', { id: documentId })
            })
            return response.data;
        },
        async GetCommunityLibraryRating(_, documentId) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GetCommunityLibraryRating', { id: documentId })
            })
            return response.data;
        }
    }
}